.tabs {
  display: inline-block;
  background-color: white;
  border-top-left-radius: rem(12);
  border-top-right-radius: rem(12);
  border-bottom: 1px solid cc(grey, lighter);
  width: 100%;
  background-color: white;

  &.-no-border-radius {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &__tab {
    padding: rem(20);
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:first-of-type {
      border-top-left-radius: rem(12);
    }

    &:last-of-type {
      border-top-right-radius: rem(12);
      border-right: none;
    }

    &.-active {
      color: cc(primary);
      cursor: inherit;
      border-bottom: 2px solid cc(primary);
    }

    .icon {
      vertical-align: text-top;
      margin-left: rem(10);
      color: inherit;
      width: rem(19);
      height: rem(19);
    }
  }

  &__seal {
    width: 100%;
    display: block;
    height: 20px;
    position: absolute;
    background-color: white;
    z-index: -1;
  }
}
