@import '~react-dates/lib/css/_datepicker.css';

.date-navigator {
  font-size: rem(18);
  font-weight: 600;
  color: black;
  white-space: nowrap;

  &::before {
    content: '';
    display: block;
    visibility: hidden;
    width: 100%;
    height: rem(15);
  }
}

.DateRangePickerInput {
  border: none;
  border-radius: rem(12);
  box-shadow: rem(5) rem(5) rem(15) rem(-5) cc(grey, dark);
}

.CalendarDay__selected_span {
  background: cc(primary, light);
  border: rem(1) double cc(primary, light);
  color: cc(text, white);

  &:hover {
    background: cc(primary, lightdark);
    border: rem(1) double cc(primary, lightdark);
    color: cc(text, white);
  }
}

.CalendarDay__selected_start {
  background: cc(primary, dark);
  border: rem(1) double cc(primary, dark);
  color: cc(text, white);

  &:hover {
    background: cc(primary, dark);
    border: rem(1) double cc(primary, dark);
    color: cc(text, white);
  }
}

.CalendarDay__selected_end {
  background: cc(primary, dark);
  border: rem(1) double cc(primary, dark);
  color: cc(text, white);

  &:hover {
    background: cc(primary, dark);
    border: rem(1) double cc(primary, dark);
    color: cc(text, white);
  }
}

.DateInput_input__focused {
  border-bottom: 2px solid cc(primary, dark);
}

.CalendarDay__hovered_span {
  background: cc(primary, light);
  border: rem(1) double cc(primary, light);
  color: cc(text, white);

  &:hover {
    background: cc(primary, lightdark);
    border: rem(1) double cc(primary, lightdark);
    color: cc(text, white);
  }
}

.SingleDatePickerInput {
  .DateInput_input__focused {
    border: 0;
    padding: 0;
  }

  .DateInput_input {
    font-size: inherit;
  }

  .CalendarDay__selected {
    background-color: cc(primary);
  }

  .DateInput_fang {
    top: rem(30) !important;
  }

  .SingleDatePicker_picker__directionLeft {
    top: rem(40) !important;
  }

  .DateInput_input {
    height: 100%;
  }
}
