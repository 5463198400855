body {
  @include type(default);
  position: relative;
  height: 100%;
  color: cc(body, text);
  background-color: cc(body, background);
  opacity: 1;
  transition: opacity 100ms linear;
  font-variant-numeric: tabular-nums;

  &.-no-scroll {
    overflow: hidden;
  }
}

#root {
  height: 100%;
}
