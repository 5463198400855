.button {
  border-radius: rem(100);
  padding: rem(10) rem(20);
  position: relative;
  cursor: pointer;

  & + & {
    margin-left: rem(10);
  }

  &.-margin-top {
    margin-top: rem(35);
  }

  &.-margin-bottom {
    margin-bottom: rem(30);
  }

  &__primary {
    color: white;
    background-color: cc(primary);
    transition: background 250ms;

    &:hover {
      background-color: cc(primary, dark);
    }
  }

  &__secondary {
    border: 1px solid cc(grey);
    transition: background 250ms;

    &:hover {
      background-color: cc(grey);
    }
  }

  &__tertiary {
    background-color: cc(grey, dark);
    transition: background 250ms;

    &:hover {
      background-color: cc(grey, darker);
    }
  }

  &__warning {
    color: white;
    background-color: cc(state, error);
    transition: background 250ms;

    &:hover {
      background-color: cc(state, error-light);
    }
  }

  &.-dropdown {
    padding: rem(10) rem(20);

    .caret-down {
      vertical-align: middle;
      margin-left: rem(5);
      margin-bottom: rem(2);
    }
  }

  &__link {
    padding: 0;

    &:hover {
      color: cc(primary);
    }
  }
}
