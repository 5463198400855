.month-navigator {
  display: inline-block;
  background-color: white;
  border-radius: rem(12);
  padding: 0 rem(5);
  box-shadow: rem(5) rem(5) rem(15) rem(-5) cc(grey, dark);

  &.-disabled {
    cursor: not-allowed;

    .month-navigator__action {
      cursor: not-allowed;

      &:hover {
        color: cc(grey, darker);
      }
    }
  }

  &__period {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
    margin-left: rem(10);
    margin-right: rem(10);
    color: cc(body, text);
    min-width: 7rem;
    text-align: center;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__action {
    vertical-align: middle;
    padding: rem(10);
    color: cc(grey, darker);

    &:hover {
      color: cc(body, text);
    }
  }

  .icon {
    vertical-align: middle;
    font-size: 18px;
  }
}
