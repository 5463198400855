.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
  z-index: 7;
  background-color: rgba(black, 0.5);
  backface-visibility: hidden;
  transition: opacity 250ms ease-in-out;
  opacity: 0;

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &__content {
    background-color: white;
    border-radius: rem(12);
    padding: rem(40);
    border: 1px solid cc(grey, dark);
    position: relative;
    max-height: 100%;
    width: 50%;

    &.-small {
      width: 33%;
    }
  }
}
