.input {
  position: relative;
  display: inline-block;
  padding-bottom: rem(20);

  &.-align-right {
    .input__field {
      text-align: right;
    }
  }

  &.-in-form {
    .select__control {
      box-shadow: none;
      padding: 0;
      border-bottom: 1px solid cc(grey, dark);

      &--is-focused {
        border-bottom: 1px solid cc(primary, dark);

        &:hover {
          border-bottom: 1px solid cc(primary, dark);
        }
      }
    }

    .input__field.-select {
      padding: 0;
    }

    .select__value-container {
      padding-bottom: 2px;
    }
  }

  &.-error {
    .input__field {
      border-bottom: 1px solid cc(state, error);
    }

    .input__select {
      .input__field {
        border-bottom: none;
      }

      .select__control {
        border-bottom: 1px solid cc(state, error);
      }
    }
  }

  &.-small {
    width: rem(50);
    padding-bottom: 0;
  }

  .caret-down {
    position: absolute;
    right: 0;
    margin-top: rem(8);
  }

  ::-ms-clear {
    display: none;
  }

  ::placeholder {
    color: cc(text, light);
    font-style: italic;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: cc(text, light);
    font-style: italic;
  }

  &.-checkbox {
    padding-bottom: rem(4);
    border-bottom: rem(1) solid transparent;
  }

  &.-title {
    padding-bottom: 0;
    width: 20%;
  }

  &__label {
    @include type(label);
    display: block;
    margin-bottom: rem(10);
    color: cc(text, light);
    height: rem(16);
    line-height: rem(16);

    &.-no-margin {
      margin: 0;
    }
  }

  &__container {
    display: inline-block;
    padding-left: rem(5);
    padding-bottom: 0;
  }

  &__field {
    border-bottom: 1px solid cc(grey, dark);
    padding: rem(5) rem(2);
    transition: border 250ms;
    width: 100%;

    &:focus {
      border-bottom: 1px solid cc(primary, dark);
    }

    &.-select {
      border-bottom: none;
    }

    &.-disabled {
      border-bottom: 1px solid cc(grey);
      color: cc(text, light);
      cursor: not-allowed;
    }

    &.-readonly {
      border-color: transparent;
    }

    &.-text-center {
      text-align: center;
    }

    &.-pointer {
      cursor: pointer;
    }
  }

  &__select {
    border-radius: rem(12);
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: left;
    padding-bottom: rem(5);

    &.-header {
      background: cc(grey, lighter);
      padding-right: rem(20);
      width: rem(300);
      font-size: rem(16);

      .select__control {
        border-radius: rem(12);
        padding: rem(6) rem(12);
      }

      .select__dropdown-indicator,
      .select__clear-indicator {
        color: cc(grey, darker);
      }

      .select__clear-indicator {
        margin-top: 2px;

        svg {
          width: rem(18);
          height: rem(18);
        }

        &:hover {
          color: cc(body, text);
        }
      }

      .select__dropdown-indicator {
        width: rem(34);
        height: rem(34);
      }
    }

    .select__control {
      cursor: text;
      border-radius: 0;
    }
  }

  &__apply {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(50%, -50%, 0);
    width: rem(23);
    height: rem(23);
  }
}

select {
  cursor: pointer;
  background: url('../assets/icons/caret-down.svg') no-repeat right white;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: rem(18);
}
