.app {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__viewport {
    height: calc(100% - #{rem(60)});
    flex-grow: 1;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
