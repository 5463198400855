.list {
  background-color: white;
  border-radius: rem(12);
  z-index: 0;

  &.-margin-bottom {
    margin-bottom: rem(30);
  }

  &__header {
    display: flex;
    padding: rem(10);
    font-weight: 500;
  }

  &__actions {
    margin-top: rem(20);
  }

  &__action-bar {
    padding: rem(16) rem(22);
    border-bottom: 1px solid cc(grey, lighter);
  }

  &__action-item {
    display: inline-block;
    margin-right: rem(40);

    .-action-label {
      font-weight: 500;
      padding-right: rem(10);
    }
  }

  &__row {
    display: flex;
    padding: rem(4) rem(10);
    position: relative;
    border-top: 1px solid cc(grey, lighter);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background-color: white;
      border-radius: rem(12);
      transform: scale(1);
      box-shadow: 0 0 0 rgba(black, 0.05);
      backface-visibility: hidden;
      transition: transform 200ms cb(), box-shadow 200ms cb(), opacity 200ms linear;
    }

    &:hover {
      &::before {
        box-shadow: 0 rem(2) rem(16) rgba(black, 0.05);
        opacity: 1;
        transform: scale(1.01, 1.05);
      }
    }

    &.-clickable {
      cursor: pointer;
    }

    &.-last-row {
      font-weight: bold;
    }
  }

  &__col {
    display: inline-block;
    height: inherit;
    line-height: rem(40);
    padding: 0 rem(12);
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;

    &.-title {
      line-height: inherit;
      padding: rem(10) rem(12);
      user-select: none;
    }

    &.-on-top {
      z-index: 1;
    }

    &.-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @for $i from 1 through 20 {
      &.-x-#{$i} {
        flex: 0 calc(#{$i} * 5%);
        max-width: calc(#{$i} * 5%);

        .-pad-right {
          padding-right: rem(5);
        }
      }
    }

    &.-bold {
      font-weight: bold;
    }

    &.-align-right {
      text-align: right;
    }

    &.-center {
      text-align: center;
    }

    &.-sortable {
      cursor: pointer;
    }

    .sort {
      float: right;
      height: 1em;
    }

    &.-sort-asc {
      path:first-child {
        fill: cc(primary);
      }
    }

    &.-sort-desc {
      path:last-child {
        fill: cc(primary);
      }
    }
  }

  &__footer {
    background-color: white;
    padding: rem(20);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: rem(12);
    border-bottom-right-radius: rem(12);
    margin-top: rem(10);

    &.-end {
      align-items: flex-end;
    }
  }

  &__message {
    padding: rem(60) rem(20);
    text-align: center;
    font-weight: 500;
    font-size: rem(16);
  }
}
