.layout {
  position: relative;
  padding: rem(60) 0;
  margin: 0 auto;
  max-width: 1620px;

  &__button-center {
    text-align: center;
  }
}
