.dropzone {
  border: 1px dashed cc(grey, dark);
  border-radius: rem(12);
  color: cc(grey, dark);
  padding: rem(40) 0;
  text-align: center;
  cursor: pointer;

  &.-disabled {
    cursor: not-allowed;
    border: 1px dashed cc(grey, dark);
  }

  &__label {
    display: block;
    margin-bottom: rem(5);
    color: cc(body, text);

    &.-bold {
      text-transform: uppercase;
      font-size: rem(25);
      font-weight: bold;
    }

    &.-small {
      font-size: rem(17);
      margin-bottom: rem(20);
    }

    &.-last {
      font-size: rem(17);
      margin-top: rem(20);
    }
  }

  &__icon {
    font-size: rem(100);
    color: cc(text, blue);
  }
}
