// cubic-bezier
@function cb($fn: default) {
  @return map-get($easings, $fn);
}

// em conversion
@function em($pixels, $context: 16) {
  @return #{$pixels / $context}em;
}

// rem conversion
@function rem($pixels) {
  @return #{$pixels / 16}rem;
}

// convert column width to %
@function column-width($span: 1, $columns: 12) {
  $cell: 100 / $columns;
  @return unquote('#{$cell * $span}%');
}

// color-control
@function cc($color, $option: false, $alpha: false) {
  $opacity: if($alpha, $alpha, if(type-of($option) == 'number', $option, false));
  $variant: if(not $option, default, if(type-of($option) == 'number', default, $option));
  $scope: map-get($color-map, $color);

  @return if($opacity, rgba(map-get($scope, $variant), $opacity), map-get($scope, $variant));
}
