.panel {
  background-color: white;
  border-radius: rem(12);
  box-shadow: rem(5) rem(5) rem(15) rem(-5) cc(grey, dark);
  padding: rem(30);

  &.-no-color {
    background-color: transparent;
    border-radius: none;
    box-shadow: none;
  }

  &.-small {
    max-width: rem(500);
  }

  &.-medium {
    max-width: rem(750);
  }

  &.-center {
    margin: 0 auto;
  }

  &__wrapper {
    display: block;
    box-shadow: rem(5) rem(5) rem(15) rem(-5) cc(grey, dark);
    border-radius: rem(12);
    background-color: white;

    & + & {
      margin-top: rem(60);
    }
  }

  &__section {
    margin-top: rem(30);
  }

  &__actions {
    margin-top: rem(20);
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content-icon {
    font-size: rem(100);

    &.-success {
      color: cc(state, success);
    }
  }

  &__content-item {
    margin-top: rem(20);

    &:last-child {
      margin-bottom: rem(20);
    }

    &.-more-margin {
      margin-top: rem(50);
    }
  }
}
