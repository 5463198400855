// color palettes
$color-map: (
  primary: (
    default: #1d90ff,
    dark: #1e65ff,
    light: #7ebfff,
    lightdark: #66a7e9,
  ),
  grey: (
    default: #e4e9ed,
    lighter: #f2f5f7,
    lightest: #f2f2f2,
    dark: #d8d8d8,
    darker: #b0b0b0,
    shadow: #dededf,
  ),
  state: (
    error: #ff4136,
    error-light: #ff675e,
    success: #1eda9a,
    warning: #ffc60b,
  ),
  body: (
    background: #f2f5f7,
    text: #303030,
  ),
  text: (
    default: #3a3a3a,
    light: #949494,
    blue: #1d90ff,
    white: #fff,
  ),
);
