.summary {
  margin-top: rem(10);

  &__item {
    font-size: rem(16);
    text-align: end;
    font-weight: 500;
    margin-bottom: rem(20);

    &:last-of-type {
      margin-bottom: rem(10);
    }
  }
}
