.editable {
  height: 100%;

  &__button {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;

    &.-align-left {
      text-align: left;
    }

    &.-align-right {
      text-align: right;
    }

    &.-error {
      border: rem(1) solid cc(state, error);
      border-radius: rem(3);
      padding-right: rem(2);
    }
  }

  .icon {
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    transition: visibility 150ms linear, opacity 150ms linear;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }

  .input {
    padding-bottom: 0;
  }

  &.-transparent {
    input,
    .SingleDatePickerInput,
    .DateInput {
      background-color: transparent !important;
      background: transparent !important;
    }
  }
}
