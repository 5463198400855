.toggle {
  height: rem(24);
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.-margin-right {
    margin-right: rem(5);
  }

  &__label {
    padding-right: rem(50);
    min-height: rem(24);
    line-height: rem(24);
    display: block;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: 500;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 12px;
    }

    &:before {
      top: 0;
      right: 0;
      width: rem(42);
      height: rem(24);
      background: #c9cfd6;
      transition: all 150ms ease;
    }

    &:after {
      width: rem(18);
      height: rem(18);
      background: #fff;
      top: rem(3);
      right: rem(21);
      box-shadow: 0 1px 3px rgba(#121621, 0.1);
      transition: all 150ms ease;
    }
  }

  &__input {
    display: none;

    &:checked {
      & + .toggle__label {
        &:before {
          background: cc(primary);
        }
        &:after {
          background: #fff;
          transform: translate(18px, 0);
        }
      }
    }
  }
}
