.switch {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  transition: opacity 100ms linear;

  &:hover {
    opacity: 0.9;
  }

  &.-small {
    .switch__track {
      height: 1.75rem;
    }

    &.-no-drop {
      &:hover {
        cursor: no-drop;
      }
    }
  }

  &__field {
    display: none;
  }

  &__track {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    box-shadow: inset 0 0 0 1px cc(primary);
    border-radius: 100px;
    overflow: hidden;
    backface-visibility: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -2.5%;
      left: -2.5%;
      width: 55%;
      height: 105%;
      background-color: cc(primary);
      border-radius: 100px;
      transform: translateZ(0);
      backface-visibility: hidden;
      transition: transform 150ms cubic-bezier(0.6, 0.05, 0, 1.15), opacity 100ms linear;

      .-single > & {
        width: 100%;
        left: 0%;
        opacity: 0;
        transform: scale(0.8);
      }
    }

    .-single > & {
      overflow: visible;
    }
  }

  &__label {
    position: relative;
    display: inline-block;
    padding: rem(10) rem(20);
    flex: 1 0 0;
    width: 50%;
    color: white;
    backface-visibility: hidden;
    transition: color 100ms linear;

    &:last-of-type {
      color: cc(primary);
    }
  }

  &__field:checked + &__track::before {
    transform: translate3d(100%, 0, 0);

    .-single > & {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__field:checked + &__track &__label {
    color: cc(primary);

    &:last-of-type {
      color: white;
    }
  }
}
