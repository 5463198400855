.header {
  display: flex;
  background-color: white;
  border-top-right-radius: rem(12);
  border-top-left-radius: rem(12);
  padding: rem(20);
  align-items: flex-end;

  &.-out-of-panel {
    background: transparent;
    padding: 0;
    margin-bottom: rem(40);
  }

  &__right {
    margin-left: auto;
  }
}
