.icon {
  display: inline-block;
  line-height: 0;
  text-decoration: inherit;
  width: 1em;
  height: 1em;
  position: relative;
  fill: currentColor;

  &.-small {
    width: rem(14);
    height: rem(14);
    justify-content: center;
  }

  &.-medium {
    width: rem(18);
    height: rem(18);
    justify-content: center;
  }

  &.-margin-left {
    margin-left: rem(10);
  }

  &.-list {
    z-index: 1;
    margin: rem(10) rem(10) rem(-10) rem(-8);
  }

  &.-visible {
    opacity: 1;
  }

  &.-invisible {
    opacity: 0;
  }
}
