.navbar {
  position: relative;
  background-color: white;
  box-shadow: 0 0.15rem 1.5rem 0 rgba(black, 0.1);
  height: rem(60);
  width: 100%;
  z-index: 3;

  &__container {
    position: relative;
    height: rem(60);
    margin: 0 auto;
    max-width: 1620px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }

  &__title {
    display: flex;
    flex: 1;
    flex-direction: row;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
