.actions {
  position: relative;
  margin-left: rem(20);
  z-index: 3;

  &__list {
    position: absolute;
    top: calc(100% - 0.25rem);
    right: 0;
    min-width: 100%;
    border-radius: 0.25rem;
    box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.2);
  }

  &__option {
    padding: 0.75rem 1rem;
    color: cc(text);
    background-color: white;
    white-space: nowrap;
    cursor: pointer;

    &:first-of-type {
      padding-top: 1.2rem;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-of-type {
      padding-bottom: 1.2rem;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      color: cc(primary);
    }

    &.-info {
      font-size: rem(13);
      color: cc(text, light);

      &:hover {
        color: cc(text, light);
        cursor: inherit;
      }
    }

    &.-mobile {
      display: none;

      @media (--mobile) {
        display: inherit;
      }
    }
  }

  &__separator {
    border-bottom: 1px solid cc(grey);
  }
}
