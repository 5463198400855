@font-face {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/NeueMontreal/NeueMontreal-Regular.woff2') format('woff2'),
    url('/static/fonts/NeueMontreal/NeueMontreal-Regular.woff') format('woff'),
    url('/static/fonts/NeueMontreal/NeueMontreal-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/NeueMontreal/NeueMontreal-Medium.woff2') format('woff2'),
    url('/static/fonts/NeueMontreal/NeueMontreal-Medium.woff') format('woff'), url('/static/fonts/NeueMontreal/NeueMontreal-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/NeueMontreal/NeueMontreal-Bold.woff2') format('woff2'),
    url('/static/fonts/NeueMontreal/NeueMontreal-Bold.woff') format('woff'), url('/static/fonts/NeueMontreal/NeueMontreal-Bold.ttf') format('ttf');
}
