.warning-block {
  background-color: #fffcf0;
  width: 100%;
  border-radius: rem(12);
  padding: rem(20);
  margin-bottom: rem(20);

  &__message {
    line-height: 1.5rem;
  }
}
