.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cc(grey, 0.6);
  z-index: 4;

  &__spinner {
    position: relative;
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
    backface-visibility: hidden;
  }

  &__animation {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    backface-visibility: hidden;
    transform-origin: 100% 50%;
    animation: spin 900ms linear both infinite;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 200%;
      height: 100%;
      border: rem(4) solid cc(primary);
      border-radius: 100%;
      transform: scale(0.75);
      backface-visibility: hidden;
    }

    & + & {
      right: 0;
      transform-origin: 0 50%;
      animation-direction: reverse;
      animation-duration: 850ms;

      &::before {
        right: 0;
        transform: scale(0.5);
        border-width: rem(4);
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
