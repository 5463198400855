.Toastify {
  &__toast {
    position: relative;
    min-height: rem(40);
    border-radius: 0.75rem;
    max-height: rem(800);
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: white;
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20);

    &-container {
      position: fixed;
      top: calc(60px + 1rem);
      right: 1rem;
      width: rem(320);
      z-index: 9999;
      -webkit-transform: translate3d(0, 0, 999px);
    }

    &-body {
      display: flex;
      padding: rem(20) rem(10);
      line-height: 1.25rem;
    }

    &--error {
      .Toastify__toast-body {
        border-left: 8px solid cc(state, error);
      }
    }
  }

  &__close-button {
    margin-right: rem(20);
  }
}

@mixin transform {
  transform: translate3d(0, 0, 0);
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes Toastify__slideOutRight {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

.Toastify__slide-enter {
  &--top-right {
    animation-name: Toastify__slideInRight;
  }
}

.Toastify__slide-exit {
  &--top-right {
    animation-name: Toastify__slideOutRight;
  }
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  animation: Toastify__trackProgress linear 1 forwards;
}
