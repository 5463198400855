.notfound {
  text-align: center;

  &__title {
    font-size: rem(100);
    font-weight: bold;
    padding-bottom: rem(20);
    margin-top: rem(30);

    .-black {
      color: cc(body, text);
    }

    .-blue {
      color: cc(primary, default);
    }
  }

  &__content {
    margin-top: rem(30);

    &.-subtitle {
      font-size: rem(20);
      font-weight: 600;
    }
  }
}
