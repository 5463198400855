// type definitions
%type-default {
  font-family: NeueMontreal, sans-serif;
  font-size: rem(15);
  font-weight: 400;
}

%type-title {
  font-size: rem(20);
  font-weight: 400;
}

%type-label {
  font-size: rem(14);
  font-weight: 500;
}
