.popdown {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0, -0.75rem, 0);
  pointer-events: none;
  z-index: 1;
  transition: transform 250ms cb(), opacity 225ms cb(ease), box-shadow 150ms cb(ease);

  &.-open {
    overflow: visible;
    opacity: 1;
    transform: translateZ(0);
    pointer-events: auto;
  }
}
