.grid {
  display: flex;
  flex: 1 0 0;
  flex-wrap: wrap;

  &__row {
    display: flex;
    flex: 1 0 0;
    flex-wrap: wrap;
    margin-left: rem(-20);
    margin-right: rem(-20);

    &.-padded-bottom {
      padding-bottom: rem(15);
    }
  }

  &__col {
    box-sizing: border-box;
    padding-right: rem(20);
    padding-left: rem(20);

    @for $i from 1 through 12 {
      &.-x-#{$i} {
        flex: 0 calc(#{$i} * 8.33333333%);
        max-width: calc(#{$i} * 8.33333333%);
      }
    }

    &.-align-right {
      text-align: right;
    }

    &.-close-to-parent {
      margin-top: rem(-10);
    }
  }
}
