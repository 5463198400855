.heading {
  @include type(title);

  display: inline-block;

  & + & {
    margin-top: rem(10);
  }

  &.-right {
    float: right;
  }

  &.-padded-right {
    padding-right: rem(15);
  }

  &.-bold {
    font-weight: 500;
    font-size: rem(25);
  }

  &.-small {
    font-size: rem(17);
  }

  &.-block {
    display: block;
  }
}
